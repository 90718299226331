<template>
  <b-col :md="md" class="menu-sidebar">
    <div class="menu">
      <router-link v-if="isAdmin()" :to="{ name: 'usuarios' }"
        ><span>Usuários</span></router-link
      >
      <router-link v-if="rdaUnit == 'BELO_HORIZONTE'" :to="{ name: 'eventos' }"
        ><span>Eventos</span></router-link
      >
      <router-link v-if="rdaUnit == 'BELO_HORIZONTE'" :to="{ name: 'banners' }"
        ><span>Banners</span></router-link
      >
      <router-link
        v-if="rdaUnit == 'BELO_HORIZONTE'"
        :to="{ name: 'notifications' }"
        ><span>Avisos</span></router-link
      >
      <router-link
        v-if="rdaUnit == 'BELO_HORIZONTE'"
        :to="{ name: 'contractors-attendances-notifications' }"
        ><span>Notificações de Faltas</span></router-link
      >
      <router-link :to="{ name: 'periods' }">
        <span>Informe Periódico</span>
      </router-link>
      <router-link
        v-if="isAdmin() && rdaUnit == 'BELO_HORIZONTE'"
        :to="{ name: 'blog' }"
        ><span>Blog</span></router-link
      >
      <router-link
        v-if="isAdmin() && rdaUnit == 'BELO_HORIZONTE'"
        :to="{ name: 'videos' }"
        ><span>Videos</span></router-link
      >
    </div>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarAdmin",

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
    }),
  },

  methods: {
    isAdmin() {
      return this.currentUser.role.some((r) => r === "admin");
    },
  },

  data() {
    return {
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
    };
  },

  props: {
    md: {
      default: 2,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
ul,
li {
  padding: 0;
}
</style>
